import { httpClient } from '@/app/shared/services';

export const KITCHEN_DOCUMENTS_STORE_MODULE_NAME = 'kitchen-documents';

export const kitchenDocumentsStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
  },
  getters: {},
  mutations: {
    SET_KITCHEN_DOCUMENTS_DATA(state, payload) {
      state.data = payload;
    },
    SET_KITCHEN_DOCUMENTS_META(state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    fetchKitchenDocumnets({ commit }) {
      return new Promise((res, rej) => {
        httpClient
          .get('/kitchens/files')
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_KITCHEN_DOCUMENTS_DATA', data);
            commit('SET_KITCHEN_DOCUMENTS_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    generateKitchenDocuments({ dispatch }, { preview, date }) {
      return new Promise((res, rej) => {
        httpClient
          .get(`/kitchens/file`, { params: { preview, date } })
          .then((response) => {
            if (!preview) {
              dispatch('fetchKitchenDocumnets');
            }
            res(response.data);
          })
          .catch((error) => rej(error));
      });
    },
    deleteDocument(_, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/kitchens/file/${id}`)
          .then((response) => {
            res(response.data);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
