<template>
  <div class="kitchen-documents">
    <div class="buttons is-flex is-justify-content-flex-end">
      <el-popover v-model="isVisibleKitchenFile" width="300">
        <el-date-picker
          v-model="dateKitchenFile"
          size="small"
          align="right"
          :picker-options="{ firstDayOfWeek: 1 }"
          style="width: 100%"
          class="mt-2 mb-2"
          format="dd MMMM yyyy"
          value-format="yyyy-MM-dd"
          placeholder="02 июля 2021"
        />
        <div style="text-align: right; margin: 0">
          <el-button type="info" size="small" style="width: 100%" @click="previewFileInServer">
            Предпросмотр
          </el-button>
          <el-button
            type="primary"
            class="mt-2 ml-0"
            size="small"
            style="width: 100%"
            @click="generateKitchenDocuments"
          >
            Файл
          </el-button>
        </div>
        <el-button slot="reference" size="small" icon="el-icon-download" type="info">
          Сгенерировать файл
        </el-button>
      </el-popover>
    </div>
    <el-table :data="data" style="width: 100%" lazy>
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="url" label="Файл">
        <template slot-scope="{ row: { url } }">
          <a :href="url" target="_blank">
            <i class="el-icon-document" />
            {{ url.split('/').pop() }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Дата" />
      <el-table-column align="right">
        <template slot-scope="{ row }">
          <el-button
            type="info"
            size="mini"
            icon="el-icon-view"
            @click="previewFileTable(row.url)"
          />
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteFile(row)" />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        class=""
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
    <documents-preview ref="documentsPreviewRef" />
  </div>
</template>

<script>
import store from '@/app/app-state';
import { onUnmounted } from '@vue/composition-api';
import { useKitchenDocumentsList } from './useKitchenDocumentsList';
import {
  KITCHEN_DOCUMENTS_STORE_MODULE_NAME,
  kitchenDocumentsStoreModule,
} from './kitchenDocumentsStoreModule';
import documentsPreview from '../documents-preview.vue';

export default {
  components: { documentsPreview },
  setup() {
    if (!store.hasModule(KITCHEN_DOCUMENTS_STORE_MODULE_NAME)) {
      store.registerModule(KITCHEN_DOCUMENTS_STORE_MODULE_NAME, kitchenDocumentsStoreModule);
    }
    onUnmounted(() => {
      if (store.hasModule(KITCHEN_DOCUMENTS_STORE_MODULE_NAME)) {
        store.unregisterModule(KITCHEN_DOCUMENTS_STORE_MODULE_NAME);
      }
    });
    const {
      meta,
      data,
      changePage,
      previewFileTable,
      documentsPreviewRef,
      previewFileInServer,
      generateKitchenDocuments,
      dateKitchenFile,
      isVisibleKitchenFile,
      deleteFile,
    } = useKitchenDocumentsList();

    return {
      meta,
      data,
      changePage,
      previewFileTable,
      documentsPreviewRef,
      previewFileInServer,
      generateKitchenDocuments,
      dateKitchenFile,
      isVisibleKitchenFile,
      deleteFile,
    };
  },
};
</script>
