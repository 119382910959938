import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import Vue from 'vue';
import moment from 'moment';
import { KITCHEN_DOCUMENTS_STORE_MODULE_NAME } from './kitchenDocumentsStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useKitchenDocumentsList() {
  const documentsPreviewRef = ref(null);
  const dateKitchenFile = ref(moment().format('YYYY-MM-DD'));
  const isVisibleKitchenFile = ref(false);

  const previewFileTable = (url) => {
    documentsPreviewRef.value.open(url);
  };

  const previewFileInServer = async () => {
    const {
      data: { url },
    } = await store.dispatch(`${KITCHEN_DOCUMENTS_STORE_MODULE_NAME}/generateKitchenDocuments`, {
      preview: true,
      date: dateKitchenFile.value,
    });
    documentsPreviewRef.value.open(url);
  };

  const generateKitchenDocuments = async () => {
    const {
      data: { url },
    } = await store.dispatch(`${KITCHEN_DOCUMENTS_STORE_MODULE_NAME}/generateKitchenDocuments`, {
      preview: false,
      date: dateKitchenFile.value,
    });
    window.location.href = url;
  };

  const fetchKitchenDocumnets = (params) => {
    store.dispatch(`${KITCHEN_DOCUMENTS_STORE_MODULE_NAME}/fetchKitchenDocumnets`, params);
  };

  const meta = computed(() => store.state[KITCHEN_DOCUMENTS_STORE_MODULE_NAME].meta);

  const data = computed(() => store.state[KITCHEN_DOCUMENTS_STORE_MODULE_NAME].data);

  const changePage = (page) => {
    router.replace({ query: { page } });
    fetchKitchenDocumnets({
      page,
    });
  };

  fetchKitchenDocumnets({
    page: routeData.query.page || 1,
  });

  const deleteFile = async ({ id }) => {
    await store.dispatch(`${KITCHEN_DOCUMENTS_STORE_MODULE_NAME}/deleteDocument`, { id });
    fetchKitchenDocumnets({
      page: routeData.query.page || 1,
    });
  };

  return {
    meta,
    data,
    changePage,
    documentsPreviewRef,
    previewFileTable,
    previewFileInServer,
    generateKitchenDocuments,
    dateKitchenFile,
    isVisibleKitchenFile,
    deleteFile,
  };
}
